<template>
  <div>
    <pl-table
      use-virtual
      class="tb-edit"
      :data="tableData"
      border
      stripe
      height="430"
      :row-height="28"
      :cell-style="{padding:0,height:'28px'}"
      :header-cell-style="{padding:0,height:'28px'}"
    >
      <pl-table-column width="100" prop="波段" :fixed="whichLock==='波段'" label="波段">
        <template slot="header">
          波段 <span @click="whichLock='波段'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="型号" :fixed="whichLock==='型号'" label="型号">
        <template slot="header">
          型号 <span @click="whichLock='型号'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="180" prop="Wafer_ID" :fixed="whichLock==='Wafer_ID'" label="Wafer ID">
        <template slot="header">
          Wafer ID <span @click="whichLock='Wafer_ID'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="110" prop="VF1_MIN" :fixed="whichLock==='VF1_MIN'" label="VF1 MIN">
        <template slot="header">
          VF1 MIN <span @click="whichLock='VF1_MIN'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="110" prop="VF1_AVG" :fixed="whichLock==='VF1_AVG'" label="VF1 AVG">
        <template slot="header">
          VF1 AVG <span @click="whichLock='VF1_AVG'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="110" prop="VF1_MAX" :fixed="whichLock==='VF1_MAX'" label="VF1 MAX">
        <template slot="header">
          VF1 MAX <span @click="whichLock='VF1_MAX'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="110" prop="VF1_STD" :fixed="whichLock==='VF1_STD'" label="VF1 STD">
        <template slot="header">
          VF1 STD <span @click="whichLock='VF1_STD'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="LOP1_MIN" :fixed="whichLock==='LOP1_MIN'" label="LOP1 MIN">
        <template slot="header">
          LOP1 MIN <span @click="whichLock='LOP1_MIN'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="LOP1_AVG" :fixed="whichLock==='LOP1_AVG'" label="LOP1 AVG">
        <template slot="header">
          LOP1 AVG <span @click="whichLock='LOP1_AVG'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="LOP1_MAX" :fixed="whichLock==='LOP1_MAX'" label="LOP1 MAX">
        <template slot="header">
          LOP1 MAX <span @click="whichLock='LOP1_MAX'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="LOP1_STD" :fixed="whichLock==='LOP1_STD'" label="LOP1 STD">
        <template slot="header">
          LOP1 STD <span @click="whichLock='LOP1_STD'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="WLP1_MIN" :fixed="whichLock==='WLP1_MIN'" label="WLP1 MIN">
        <template slot="header">
          WLP1 MIN <span @click="whichLock='WLP1_MIN'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="WLP1_AVG" :fixed="whichLock==='WLP1_AVG'" label="WLP1 AVG">
        <template slot="header">
          WLP1 AVG <span @click="whichLock='WLP1_AVG'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="125" prop="WLP1_MAX" :fixed="whichLock==='WLP1_MAX'" label="WLP1 MAX">
        <template slot="header">
          WLP1 MAX <span @click="whichLock='WLP1_MAX'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="115" prop="WLP1_STD" :fixed="whichLock==='WLP1_STD'" label="WLP1 STD">
        <template slot="header">
          WLP1 STD <span @click="whichLock='WLP1_STD'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="120" prop="WLD1_MIN" :fixed="whichLock==='WLD1_MIN'" label="WLD1 MIN">
        <template slot="header">
          WLD1 MIN <span @click="whichLock='WLD1_MIN'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="120" prop="WLD1_AVG" :fixed="whichLock==='WLD1_AVG'" label="WLD1 AVG">
        <template slot="header">
          WLD1 AVG <span @click="whichLock='WLD1_AVG'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="125" prop="WLD1_MAX" :fixed="whichLock==='WLD1_MAX'" label="WLD1 MAX">
        <template slot="header">
          WLD1 MAX <span @click="whichLock='WLD1_MAX'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="120" prop="WLD1_STD" :fixed="whichLock==='WLD1_STD'" label="WLD1 STD">
        <template slot="header">
          WLD1 STD <span @click="whichLock='WLD1_STD'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="IR_MIN" :fixed="whichLock==='IR_MIN'" label="IR MIN">
        <template slot="header">
          IR MIN <span @click="whichLock='IR_MIN'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="IR_AVG" :fixed="whichLock==='IR_AVG'" label="IR AVG">
        <template slot="header">
          IR AVG <span @click="whichLock='IR_AVG'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="IR_MAX" :fixed="whichLock==='IR_MAX'" label="IR MAX">
        <template slot="header">
          IR MAX <span @click="whichLock='IR_MAX'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="IR_STD" :fixed="whichLock==='IR_STD'" label="IR STD">
        <template slot="header">
          IR STD <span @click="whichLock='IR_STD'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="100" prop="Qty" :fixed="whichLock==='Qty'" label="Qty">
        <template slot="header">
          Qty <span @click="whichLock='Qty'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column v-if="tableName==='scan'" width="100" prop="Qty扫描" :fixed="whichLock==='Qty扫描'"
                       label="Qty扫描">
        <template slot="header">
          Qty扫描 <span @click="whichLock='Qty扫描'"><i class="el-icon-s-flag"></i></span>
        </template>
        <template slot-scope="scope">
          <span
            style="float:left;width:100%;height:100%"
            @click="ChangeRow(scope.row)"
            v-show="whichEdit!==scope.row"
          >{{ scope.row.Qty扫描 }}</span>
          <el-input
            v-show="whichEdit===scope.row"
            type="text"
            size="mini"
            v-model="scope.row.Qty扫描"
            @blur="DoChangeValue(scope.row)"
            @keydown.native.enter="EnterQty(scope.row)"
            :ref="'edit_'+scope.row.Wafer_ID"
          ></el-input>
        </template>
      </pl-table-column>
      <pl-table-column v-if="tableName==='so'" width="100" prop="Qty修正" :fixed="whichLock==='Qty修正'"
                       label="Qty修正">
        <template slot="header">
          Qty修正 <span @click="whichLock='Qty修正'"><i class="el-icon-s-flag"></i></span>
        </template>
        <template slot-scope="scope">
          <span
            style="float:left;width:100%;height:100%"
            @click="ChangeRow(scope.row)"
            v-show="whichEdit!==scope.row"
          >{{ scope.row.Qty修正 }}</span>
          <el-input
            v-show="whichEdit===scope.row"
            type="text"
            size="mini"
            v-model="scope.row.Qty修正"
            @blur="DoChangeValue(scope.row)"
            @keydown.native.enter="EnterQty(scope.row)"
            :ref="'edit_'+scope.row.Wafer_ID"
          ></el-input>
        </template>
      </pl-table-column>
      <pl-table-column width="120" prop="SERIAL" :fixed="whichLock==='SERIAL'" label="SERIAL">
        <template slot="header">
          SERIAL <span @click="whichLock='SERIAL'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column width="90" label="删除" v-if="tableName==='so'">
        <template slot-scope="scope">
          <el-button size="mini" style="padding:5px 8px" type="danger" plain @click="DelRow(scope.row)">删除</el-button>
        </template>
      </pl-table-column>
    </pl-table>
  </div>
</template>

<script>
import {squareScanEdit} from "@/http/api";

export default {
  name: "CsvTable",
  props: ['tableData', 'tableName'],
  data() {
    return {
      whichLock: 'Wafer_ID',
      whichEdit: {},
      token: this.$cookies.get('token'),
    }
  },
  methods: {
    ChangeRow(row) {
      this.whichEdit = row
      const ref = 'edit_' + row.Wafer_ID
      setTimeout(() => this.$refs[ref].select(), 10)
    },
    DoChangeValue(row) {
      let Qty = row.Qty扫描 || row.Qty修正
      if (!Qty || isNaN(Qty)) {
        this.$message({
          type: "warning",
          message: 'Qty必须是一个数字'
        })
      } else {
        this.whichEdit = {}
        squareScanEdit({
          token: this.token,
          row,
        })
          .catch(err => console.log(err))
      }
    },
    EnterQty(row) {
      let Qty = row.Qty扫描 || row.Qty修正
      if (!Qty || isNaN(Qty)) {
        this.$message({
          type: "warning",
          message: 'Qty必须是一个数字'
        })
      } else {
        this.whichEdit = {}
      }
    },
    DelRow(row) {
      this.$emit('del-row', {row, tableName: this.tableName})
    }
  },
}
</script>

<style scoped>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
