<template>
  <div>
    <el-form>
      <el-form-item v-if="formName==='sm'" label="波段" label-width="120px">
        <el-select v-model="form.波段" clearable placeholder="-波段-" @change="BoDuanChg">
          <el-option
            v-for="item in 波段"
            :key="item.label"
            :value="item.label"
            :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formName==='sm'" label="型号" label-width="120px">
        <el-select v-model="form.型号" clearable placeholder="-型号-">
          <el-option
            v-for="item in 型号"
            :key="item"
            :value="item"
            :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态信息" label-width="120px">
        <el-select v-model="form.状态信息" clearable placeholder="-状态信息-" style="width: 120px">
          <el-option
            v-for="item in 状态信息"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formName==='sm'" label="来源" label-width="120px">
        <el-select v-model="form.来源" clearable placeholder="-来源-" style="width: 120px">
          <el-option
            v-for="item in 来源"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formName==='sm'" label="来源厂家" label-width="120px">
        <el-select
          v-model="form.来源厂家"
          remote
          reserve-keyword
          :remote-method="GetSource"
          filterable
          clearable
          allow-create
          placeholder="来源厂家"
          default-first-option
        >
          <el-option
            v-for="item in 来源厂家"
            :key="item.来源厂家"
            :label="item.来源厂家"
            :value="item.来源厂家">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最新动作" label-width="120px">
        <el-select v-model="form.最新动作" clearable placeholder="-最新动作-" style="width: 120px">
          <el-option
            v-for="item in 最新动作"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库动作" label-width="120px">
        <el-select v-model="form.出库动作" clearable placeholder="-出库动作-" style="width: 120px">
          <el-option
            v-for="item in 出库动作"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员" label-width="120px">
        <el-select v-model="form.业务员" clearable placeholder="-业务员-" style="width: 120px">
          <el-option
            v-for="item in 业务员"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formName==='sm'" label="入库时间" label-width="120px">
        <el-date-picker
          v-model="form.入库时间"
          type="date"
          placeholder="入库时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="送货单日期" label-width="120px">
        <el-date-picker
          v-model="form.送货单日期"
          type="date"
          placeholder="送货单日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发货时间" label-width="120px">
        <el-date-picker
          v-model="form.发货时间"
          type="date"
          placeholder="发货时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="客户" label-width="120px">
        <el-select
          v-model="form.客户"
          remote
          reserve-keyword
          :remote-method="GetCustom"
          filterable
          clearable
          allow-create
          placeholder="客户"
          default-first-option
        >
          <el-option
            v-for="item in 客户"
            :key="item.公司"
            :label="item.公司"
            :value="item.公司">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formName==='so'" label="单价" label-width="120px">
        <el-input
          v-model="form.单价"
          clearable
          style="width:240px"
          placeholder="0.00"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="formName==='so'" label="发货单号" label-width="120px">
        <el-input
          v-model="form.发货单号"
          clearable
          style="width:400px"
          placeholder="顺丰快递：XXXXXXXX"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" label-width="120px">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="备注信息"
          v-model="form.备注">
        </el-input>
      </el-form-item>
      <el-form-item label-width="120px">
        <el-button type="primary" plain @click="SubForm">{{ SubBtnText }}</el-button>
        <el-button plain @click="Back">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  ZhuangTaiList,
  ZXDongZuoList,
  CKDongZuoList,
  YeWuYuanList,
  LaiYuanList,
  BoDuanList,
} from '../configs/squareConfig'
import {getCustom, getSourceList} from "../http/api";

export default {
  name: "SquareForm",
  props: ['formData', 'formName'],
  data() {
    return {
      token: this.$cookies.get('token'),
      最新动作: ZXDongZuoList,
      出库动作: CKDongZuoList,
      业务员: YeWuYuanList,
      状态信息: ZhuangTaiList,
      客户: [],
      来源: LaiYuanList,
      来源厂家: [],
      波段: BoDuanList,
      型号: [],
      form: this.formData,
    }
  },
  methods: {
    GetSource(query) {
      const token = this.token
      const keywords = query
      getSourceList({token, keywords})
        .then(res => this.来源厂家 = res.data.rs)
        .catch(err => console.log(err))
    },
    GetCustom(query) {
      const token = this.token
      const keywords = query
      getCustom({token, keywords})
        .then(res => this.客户 = res.data.rs)
        .catch(err => console.log(err))
    },
    SubForm() {
      this.$emit('sub-form', this.formName, this.form)
    },
    BoDuanChg() {
      const b = this.form.波段
      delete this.form.型号
      if (b) {
        const f = this.波段.find(item => item.label === b)
        this.型号 = f.value
      }
    },
    Back() {
      this.$emit('back', this.formName)
    },
    Clear(form) {
      this.form = form
    }
  },
  computed: {
    SubBtnText() {
      const formName = this.formName
      if (formName === 'sm') return '保存为正式数据'
      if (formName === 'so') return '一键出库'
    }
  },
}
</script>

<style scoped>

</style>
