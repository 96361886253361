<template>
  <div>
    <div class="page-title">方片插件库</div>
    <div style="width:100%;float:left;margin-top:1rem">
      <el-button type="primary" plain @click="csvLayer=step1=true;step2=step3=false">西安中为 CSV 导入</el-button>
      <el-button type="success" plain @click="xlsxLayer=step1=true;step2=step3=false">表格文件批量导入</el-button>
      <el-button type="primary" plain @click="ShowSource">查看入库源数据</el-button>
    </div>
    <div style="width:100%;float:left;margin-top:1rem">
      <el-button type="primary" plain @click="SmLayer">扫码入库</el-button>
      <el-button type="success" plain @click="SoLayer">扫码出库</el-button>
      <el-button type="primary" plain @click="SmtLayer">方片智能筛选</el-button>
      <el-button type="primary" plain @click="QKLayer">片号速对</el-button>
    </div>
    <!-- 西安中为 csv 导入层 -->
    <el-dialog
      title="西安中为 csv 压缩包导入"
      :visible.sync="csvLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="csv-upload"
        class="upload-demo"
        drag
        :action="csvUrl"
        :on-success="function(res){return CsvAfterU(res)}"
        :before-upload="function(file){return CsvBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 zip 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 压缩时直接批量选中 csv 文件进行压缩</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将自动计算所有数据</div>
      </el-upload>
      <div style="float:left;width:100%" v-show="step2">
        <csv-table :table-data="csvConfirm.rs" table-name="csv"></csv-table>
        <div style="margin-top:1rem;float:left;width:100%">
          <span style="float:left;line-height:2.2;padding-right:1rem">共计颗粒数：{{ csvConfirm.sumQty }} </span>
          <el-pagination
            @current-change="CsvPageChg"
            :current-page="csvConfirm.page+1"
            :page-size="csvConfirm.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="csvConfirm.count">
          </el-pagination>
        </div>
      </div>
      <div style="float:left;width:100%;margin-top:1rem">
        <el-button type="primary" v-show="step2" @click="CsvDo" plain>确认导入</el-button>
        <el-button @click="CsvCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- xlsx 批量导入层 -->
    <el-dialog
      title="方片表格压缩包导入"
      :visible.sync="xlsxLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="xls-upload"
        class="upload-demo"
        drag
        :action="xlsxUrl"
        :on-success="function(res){return XlsxAfterU(res)}"
        :before-upload="function(file){return XlsxBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 zip 格式的压缩包</div>
        <div class="el-upload__tip" slot="tip">2. 压缩时直接批量选中 xlsx 文件进行压缩</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将自动处理所有数据</div>
      </el-upload>
      <div style="float:left;width:100%" v-show="step2">
        <csv-table :table-data="xlsxConfirm.rs" table-name="xls"></csv-table>
        <div style="margin-top:1rem;float:left;width:100%">
          <span style="float:left;line-height:2.2;padding-right:1rem">共计颗粒数：{{ xlsxConfirm.sumQty }} </span>
          <el-pagination
            @current-change="XlsxPageChg"
            :current-page="xlsxConfirm.page+1"
            :page-size="xlsxConfirm.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="xlsxConfirm.count">
          </el-pagination>
        </div>
      </div>
      <div style="float:left;width:100%;margin-top:1rem">
        <el-button type="primary" v-show="step2" @click="XlsxDo" plain>确认导入</el-button>
        <el-button @click="XlsxCl" type="danger" plain>取 消</el-button>
      </div>
      <div style="float:left;width:100%;height:1.5rem"></div>
    </el-dialog>
    <!-- 入库源数据管理层 -->
    <el-dialog
      title="入库源数据管理"
      :visible.sync="sdLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <div style="float:left;width:100%">
        <el-input
          v-model="sdForm.keywords"
          style="width:200px"
          @keydown.native.enter="GetSourceData(0)"
          clearable
        ></el-input>
        <el-button type="primary" plain @click="GetSourceData(0)">搜索</el-button>
        <el-button type="danger" style="margin-left:1rem" @click="SdClear">清空源数据</el-button>
        <el-button type="success" plain @click="PrintBarCode">打印条码</el-button>
        <el-button type="danger" plain @click="sdLayer=false;sdForm={}">关闭</el-button>
      </div>
      <div style="float:left;width:100%;margin-top:1rem">
        <csv-table :table-data="sdConfirm.rs" table-name="sd"></csv-table>
        <el-pagination
          style="margin-top:1rem"
          @current-change="SdPageChg"
          :current-page="sdConfirm.page+1"
          :page-size="sdConfirm.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="sdConfirm.count">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 扫码入库层 -->
    <el-dialog
      title="方片扫码入库"
      :visible.sync="smLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <div style="float:left;width:100%" v-show="step1">
        <el-form>
          <el-form-item label="Wafer ID：" label-width="100px">
            <el-input
              placeholder="请扫描标签上的片号"
              ref="Wafer_ID"
              @keydown.native.enter="SmScan"
              v-model="smForm.Wafer_ID"
              style="width:500px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="Qty：" label-width="100px">
            <el-input
              placeholder="请扫描标签上的Qty"
              ref="Qty"
              v-model="smForm.Qty"
              @keydown.native.enter="SmSave"
              style="width:500px"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="float:left;margin-top:1rem;padding-left:10px">
        累计已扫 {{ smSaveData.length }} 片，颗粒数：原数据 {{ SmSumQty('data') }} / 扫码 {{ SmSumQty('scan') }}
        <el-button
          style="margin-left:1rem"
          @click="step1=true;step2=step3=false"
          type="primary"
          v-show="step2"
          plain
        >返回
        </el-button>
        <el-button
          style="margin-left:1rem"
          @click="step1=step2=false;step3=true"
          type="success"
          v-show="step2 && smSaveData.length>0"
          plain
        >下一步
        </el-button>
        <el-button
          @click="SmClear"
          type="danger"
          v-show="step2"
        >清空
        </el-button>
        <el-button
          style="margin-left:1rem"
          type="primary"
          v-show="step1"
          @click="SmShowTable"
          plain>查看已扫描
        </el-button>
        <el-button
          @click="SmCl"
          type="danger"
          plain>取 消
        </el-button>
      </div>
      <div v-show="step1 && smForm.Wafer_ID" v-for="item in smConfirm">
        <div style="float:left;width:90%;margin-top:1rem;padding-left:100px">
          <table>
            <tr style="font-weight:bold;">
              <td>Wafer ID</td>
              <td>Bin Value</td>
              <td style="color:orangered">Qty</td>
              <td>SERIAL</td>
              <td>数据来源</td>
              <td>选择使用</td>
            </tr>
            <tr>
              <td>{{ item.Wafer_ID }}</td>
              <td>{{ item.Bin_Value }}</td>
              <td style="color:orangered">{{ item.Qty }}</td>
              <td>{{ item.SERIAL }}</td>
              <td>{{ item.数据表 }}</td>
              <td>
                <el-button type="success" :plain="item!==smSaveWhich" size="mini" @click="SmUseData(item)">
                  {{ item === smSaveWhich ? '已使用本条数据' : '选择此条数据' }}
                </el-button>
              </td>
            </tr>
          </table>
        </div>
        <div style="float:left;width:90%;margin-top:1rem;padding-left:100px">
          <table>
            <tr style="font-weight:bold;">
              <td>Item</td>
              <td>Min</td>
              <td>Avg</td>
              <td>Max</td>
              <td>Std</td>
            </tr>
            <tr>
              <td style="font-weight:bold">VF</td>
              <td>{{ item.VF1_MIN }}</td>
              <td>{{ item.VF1_AVG }}</td>
              <td>{{ item.VF1_MAX }}</td>
              <td>{{ item.VF1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">WLP</td>
              <td>{{ item.WLP1_MIN }}</td>
              <td>{{ item.WLP1_AVG }}</td>
              <td>{{ item.WLP1_MAX }}</td>
              <td>{{ item.WLP1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">WLD</td>
              <td>{{ item.WLD1_MIN }}</td>
              <td>{{ item.WLD1_AVG }}</td>
              <td>{{ item.WLD1_MAX }}</td>
              <td>{{ item.WLD1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">LOP</td>
              <td>{{ item.LOP1_MIN }}</td>
              <td>{{ item.LOP1_AVG }}</td>
              <td>{{ item.LOP1_MAX }}</td>
              <td>{{ item.LOP1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">IR</td>
              <td>{{ item.IR_MIN }}</td>
              <td>{{ item.IR_AVG }}</td>
              <td>{{ item.IR_MAX }}</td>
              <td>{{ item.IR_STD }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-show="step2" style="float:left;width:100%;margin-top:1rem">
        <csv-table :table-data="smSaveData" table-name="scan"></csv-table>
      </div>
      <div style="float:left;width:100%;margin-top:1rem" v-show="step3">
        <square-form
          :form-data="smFormData"
          form-name="sm"
          @sub-form="SubForm"
          @back="Back"
          ref="smForm"
        ></square-form>
      </div>
      <div style="float:left;width:100%;height:1.5rem"></div>
    </el-dialog>
    <!-- 扫码出库层 -->
    <el-dialog
      title="方片扫码出库"
      :visible.sync="soLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <div style="float:left;width:100%" v-show="step1">
        <el-form @submit.native.prevent>
          <el-form-item label="Wafer ID：" label-width="100px">
            <el-input
              placeholder="请扫描标签上的片号"
              ref="so_wid"
              @keydown.native.enter="SoWid"
              v-model="soForm.Wafer_ID"
              style="width:500px"
              clearable
            ></el-input>
          </el-form-item>
          <!--
          <el-form-item label="Qty：" label-width="100px">
            <el-input
              placeholder="请扫描标签上的Qty"
              ref="so_Qty"
              v-model="soForm.Qty"
              @keydown.native.enter="SoSave"
              style="width:500px"
              clearable
            ></el-input>
          </el-form-item>
          -->
        </el-form>
      </div>
      <div style="float:left;margin-top:1rem;padding-left:10px">
        累计已扫 {{ soSaveData.length }} 片，颗粒数：{{ SoSumQty }}
        <el-button
          style="margin-left:1rem"
          @click="step1=true;step2=step3=false"
          type="primary"
          v-show="step2"
          plain
        >返回
        </el-button>
        <el-button
          style="margin-left:1rem"
          @click="step1=step2=false;step3=true"
          type="success"
          v-show="step2 && soSaveData.length>0"
          plain
        >下一步
        </el-button>
        <el-button
          @click="SoClear"
          type="danger"
          v-show="step2"
        >清空
        </el-button>
        <el-button
          style="margin-left:1rem"
          type="primary"
          v-show="step1"
          @click="SoShowTable"
          plain>查看已扫描
        </el-button>
        <el-button
          @click="SoCl"
          type="danger"
          plain>取 消
        </el-button>
      </div>
      <div v-show="step1 && soForm.Wafer_ID" v-for="item in soConfirm">
        <div style="float:left;width:90%;margin-top:1rem;padding-left:100px">
          <table>
            <tr style="font-weight:bold;">
              <td>Wafer ID</td>
              <td>Bin Value</td>
              <td style="color:orangered">Qty</td>
              <td>SERIAL</td>
              <td>选择使用</td>
            </tr>
            <tr>
              <td>{{ item.Wafer_ID }}</td>
              <td>{{ item.Bin_Value }}</td>
              <td style="color:orangered">{{ item.Qty }}</td>
              <td>{{ item.SERIAL }}</td>
              <td>
                <el-button type="success" :plain="item!==soSaveWhich" size="mini" @click="SoUseData(item)">
                  {{ item === soSaveWhich ? '已使用本条数据' : '选择此条数据' }}
                </el-button>
              </td>
            </tr>
          </table>
        </div>
        <div style="float:left;width:90%;margin-top:1rem;padding-left:100px">
          <table>
            <tr style="font-weight:bold;">
              <td>Item</td>
              <td>Min</td>
              <td>Avg</td>
              <td>Max</td>
              <td>Std</td>
            </tr>
            <tr>
              <td style="font-weight:bold">VF</td>
              <td>{{ item.VF1_MIN }}</td>
              <td>{{ item.VF1_AVG }}</td>
              <td>{{ item.VF1_MAX }}</td>
              <td>{{ item.VF1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">WLP</td>
              <td>{{ item.WLP1_MIN }}</td>
              <td>{{ item.WLP1_AVG }}</td>
              <td>{{ item.WLP1_MAX }}</td>
              <td>{{ item.WLP1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">WLD</td>
              <td>{{ item.WLD1_MIN }}</td>
              <td>{{ item.WLD1_AVG }}</td>
              <td>{{ item.WLD1_MAX }}</td>
              <td>{{ item.WLD1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">LOP</td>
              <td>{{ item.LOP1_MIN }}</td>
              <td>{{ item.LOP1_AVG }}</td>
              <td>{{ item.LOP1_MAX }}</td>
              <td>{{ item.LOP1_STD }}</td>
            </tr>
            <tr>
              <td style="font-weight:bold">IR</td>
              <td>{{ item.IR_MIN }}</td>
              <td>{{ item.IR_AVG }}</td>
              <td>{{ item.IR_MAX }}</td>
              <td>{{ item.IR_STD }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-show="step2" style="float:left;width:100%;margin-top:1rem">
        <csv-table :table-data="soSaveData" table-name="so" @del-row="DelRow"></csv-table>
      </div>
      <div style="float:left;width:100%;margin-top:1rem" v-show="step3">
        <square-form
          :form-data="soFormData"
          form-name="so"
          @sub-form="SubForm"
          @back="Back"
          ref="soForm"
        ></square-form>
      </div>
      <div style="float:left;width:100%;height:1.5rem"></div>
    </el-dialog>
    <!-- 智能筛选层 -->
    <el-dialog
      title="方片智能筛选"
      :visible.sync="smtLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <table>
        <tr>
          <td colspan="2" width="300px">
            <el-select
              v-model="smtForm.波段"
              @change="WChange"
              filterable
              clearable
              allow-create
              default-first-option
            >
              <el-option v-for="波段 in 波段型号" :value="波段.label">{{ 波段.label }}</el-option>
            </el-select>
          </td>
          <td colspan="2" width="300px">
            <el-select
              v-model="smtForm.型号"
              filterable
              clearable
              allow-create
              default-first-option
            >
              <el-option v-for="型号 in 型号列表" :value="型号">{{ 型号 }}</el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>Item</td>
          <td>MIN</td>
          <td>AVG</td>
          <td>MAX</td>
        </tr>
        <tr>
          <td>VF1</td>
          <td>
            <el-input v-model="smtForm.VF1_MIN" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.VF1_AVG" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.VF1_MAX" clearable/>
          </td>
        </tr>
        <tr v-if="!smtForm.波段 || (smtForm.波段 && smtForm.波段.indexOf('波段')!==-1)">
          <td>WLP1</td>
          <td>
            <el-input v-model="smtForm.WLP1_MIN" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.WLP1_AVG" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.WLP1_MAX" clearable/>
          </td>
        </tr>
        <tr v-if="!smtForm.波段 || (smtForm.波段 && smtForm.波段.indexOf('波段')===-1)">
          <td>WLD1</td>
          <td>
            <el-input v-model="smtForm.WLD1_MIN" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.WLD1_AVG" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.WLD1_MAX" clearable/>
          </td>
        </tr>
        <tr>
          <td>LOP1</td>
          <td>
            <el-input v-model="smtForm.LOP1_MIN" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.LOP1_AVG" clearable/>
          </td>
          <td>
            <el-input v-model="smtForm.LOP1_MAX" clearable/>
          </td>
        </tr>
      </table>
      <el-input v-model="smtForm.目标数量" placeholder="请输入目标颗粒数" style="width:18rem">
        <span slot="suffix" style="line-height:2.8;">单位 (K)</span>
      </el-input>
      <el-select v-model="smtForm.保留小数" placeholder="请选择保留小数位">
        <el-option v-for="bit in [3,2,1]" :value="bit">保留 {{ bit }} 位小数</el-option>
      </el-select>
      <el-button style="margin-top:1rem" plain type="primary" @click="SmtSearch">智能筛选</el-button>
    </el-dialog>
    <!-- 快速核对片 -->
    <el-dialog
      title="方片片号速对"
      :visible.sync="qkLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <div style="width:100%;float:left;padding:1rem 0">
        <el-input
          type="textarea"
          style="width:80rem"
          :rows="20"
          placeholder="请在此处粘贴要使用的方片片号"
          v-model="qkForm.Wafer_ID"
          v-show="step1"
        ></el-input>
        <div style="width:100%;max-height:100%;min-height:30rem;float:left;" v-if="step2" :style="GetQKBg">
          <el-input
            ref="qk"
            v-model="qkForm.cwid"
            style="width:300px;margin:2rem"
            @keydown.native.enter="QKEnt"
          ></el-input>
        </div>
      </div>
      <el-button v-show="step1" style="margin-top:1rem" plain type="primary" @click="QKNext">下一步</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl, BoDuanList} from "@/configs/squareConfig";
import CsvTable from "../components/CsvTable";
import {Loading} from "element-ui";
import {
  csvIntoCl,
  csvIntoDo,
  csvIntoGet,
  squareClearSourceData,
  squareGetSourceData,
  squareScanClear,
  squareScanGetCache,
  squareScanId,
  squareScanSaveDo,
  squareScanSubForm,
  squareXlsxGetCache,
  squareXlsxIntoDo,
  squareSoWaferID,
  squareSoClear,
  squareSoUseWhich,
  squareSoGetCache,
  squareSoSubForm,
  squareScanDelRow,
  getSmtSKU,
} from "@/http/api";
import SquareForm from "../components/SquareForm";
import moment from "moment";
import {formRequest} from "@/http/formRequest";

let loading
export default {
  name: "ProdSquarePlugin",
  components: {SquareForm, CsvTable},
  data() {
    return {
      token: this.$cookies.get('token'),
      step1: true,
      step2: false,
      step3: false,
      csvLayer: false,
      xlsxLayer: false,
      csvUrl: baseUrl + '/square-plugin-csv-upload',
      csvConfirm: {
        rs: [],
      },
      smLayer: false,
      smForm: {},
      smConfirm: [],
      smSaveWhich: {},
      smSaveData: [],
      smFormData: {
        状态信息: '在库',
        入库时间: moment().format('YYYY/MM/DD'),
        最新动作: '无',
      },
      sdLayer: false,
      sdConfirm: [],
      sdForm: {},
      xlsxUrl: baseUrl + '/square-plugin-xls-upload',
      xlsxConfirm: {
        rs: []
      },
      soLayer: false,   //  出库层显示与否
      soForm: {},       //  保存扫描的 WaferID、Qty
      soConfirm: [],    //  应扫描条码的数据
      soSaveData: [],   //  所有已扫描的数据
      soSaveWhich: {},  //  被选择使用的数据
      soFormData: {     //  扫描结果 批量修改的表单
        状态信息: '出库',
        发货时间: moment().format('YYYY/MM/DD'),
      },
      smtLayer: false,
      smtForm: {},
      波段型号: BoDuanList,
      型号列表: [],
      qkLayer: false,
      qkForm: {},
    }
  },
  methods: {
    /* 西安中为 csv 导入 */
    CsvBeforeU(file) {  // csv 导入成功前
      loading = Loading.service({
        fullscreen: true,
        text: '正在逐个文件处理，可能要花一点时间，请您耐心等待...'
      })
    },
    CsvAfterU(res) {    // csv 上传成功后
      loading.close()
      if (res.code !== 'OK') {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      } else {
        this.csvConfirm = res
        this.step1 = false
        this.step2 = true
      }
    },
    CsvPageChg(e) {
      e = ~~e
      e--
      csvIntoGet({
        token: this.token,
        page: e,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.csvConfirm.rs = res.data.rs
          }
        })
        .catch(err => console.log(err))
    },
    CsvDo() {   // csv 执行导入
      csvIntoDo({
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '已成功导入到原始数据表'
            })
            this.csvConfirm = {rs: []}
            this.step2 = this.step3 = this.csvLayer = false
            this.step1 = true
          }
        })
        .catch(err => console.log(err))
    },
    CsvCl() {   // csv 取消导入
      this.step2 = this.step3 = this.csvLayer = false
      this.step1 = true
      const data = this.csvConfirm
      if (data.filePath || data.fullPath || data.uploadTime) {
        csvIntoCl({
          token: this.token,
          filePath: data.filePath,
          fullPath: data.fullPath,
          uploadTime: data.uploadTime,
        })
          .then(res => console.log(res))
          .catch(err => console.log(err))
      }
      this.csvConfirm = {rs: []}
    },
    /* xlsx 批量导入 */
    XlsxBeforeU(file) {   // xlsx 批量上传成功前
      loading = Loading.service({
        fullscreen: true,
        text: '正在逐个处理表格数据，请耐心等待一会儿...'
      })
    },
    XlsxAfterU(res) {   // xlsx 批量上传成功后
      loading.close()
      if (res.code === 'OK') {
        this.step1 = this.step3 = false
        this.step2 = true
        this.xlsxConfirm = res
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      }
    },
    XlsxPageChg(page) {   // xlsx 临时数据翻页
      page--
      squareXlsxGetCache({
        token: this.token,
        page,
      })
        .then(res => {
          if (res.data.code === 'OK') this.xlsxConfirm = res.data.rs
        })
        .catch(err => console.log(err))
    },
    XlsxDo() {   // xlsx 执行导入
      squareXlsxIntoDo({
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '已成功导入到原始数据表'
            })
            this.xlsxConfirm = {rs: []}
            this.step2 = this.step3 = this.xlsxLayer = false
            this.step1 = true
          }
        })
        .catch(err => console.log(err))
    },
    XlsxCl() {    // xlsx 取消操作
      this.step1 = true
      this.step2 = this.step3 = this.xlsxLayer = false
      const data = this.xlsxConfirm
      if (data.filePath || data.fullPath || data.uploadTime) {
        csvIntoCl({
          token: this.token,
          filePath: data.filePath,
          fullPath: data.fullPath,
          uploadTime: data.uploadTime,
        })
          .then(res => console.log(res))
          .catch(err => console.log(err))
      }
      this.xlsxConfirm = {rs: []}
    },
    /* 入库源数据 */
    ShowSource() {
      this.sdLayer = true
      this.GetSourceData(0)
    },
    GetSourceData(page) {
      squareGetSourceData({
        token: this.token,
        form: this.sdForm,
        page,
      })
        .then(res => {
          if (res.data.code === 'OK') this.sdConfirm = res.data
        })
        .catch(err => console.log(err))
    },
    SdPageChg(p) {
      p--
      this.GetSourceData(p)
    },
    SdClear() {
      this.$confirm('确认要清空源数据表吗？')
        .then(res => {
          const token = this.token
          squareClearSourceData({token})
            .then(res => {
              if (res.data.code === 'OK') {
                this.sdLayer = false
                this.sdForm = {}
                this.$message({
                  type: 'success',
                  message: '源数据已被清空，若您需要扫码入库，请重新导入源数据'
                })
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    PrintBarCode() {
      const url = baseUrl + '/square-plugin-print-barcode'
      formRequest(url, {token: this.token})
    },
    /* 扫码入库 */
    SmLayer() {
      squareScanGetCache({
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') this.smSaveData = res.data.rs
        })
        .catch(err => console.log(err))
      this.step1 = this.smLayer = true
      this.step2 = this.step3 = false
      setTimeout(() => {
        this.$refs.Wafer_ID.select()
      }, 10)
    },
    SmScan() {   // Wafer ID 扫描完成后
      this.smSaveWhich = {}
      const Wafer_ID = this.smForm.Wafer_ID
      if (!Wafer_ID) {
        this.$message({
          type: 'warning',
          message: 'Wafer ID 不为空'
        })
        return
      }
      squareScanId({
        token: this.token,
        Wafer_ID,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            const json = res.data.json
            const count = json.filter(item => item.数据表 === '入库源数据').length
            if (count < 1) {
              this.$message({
                type: 'error',
                message: '请注意！入库源数据表中未找到数据'
              })
            }
            if (json.length === 0) this.$alert(`未找到任何相关数据`)
            if (json.length > 1) this.$alert(`请注意！Wafer ID 重复。共 ${json.length} 条`)
            if (json.length === 1 && count === 1) this.smSaveWhich = json[0]
            this.smConfirm = json
            this.$refs.Qty.select()
          }
        })
        .catch(err => console.log(err))
    },
    SmSave() {    // Qty 也扫描完成
      if (!this.smForm.Wafer_ID || !this.smForm.Qty) {
        this.$message({
          type: 'warning',
          message: '请扫描 Wafer ID 和 Qty'
        })
        return
      }
      if (!this.smSaveWhich.Wafer_ID) {
        this.$message({
          type: 'warning',
          message: '请选择对应数据',
        })
        return
      }
      this.smSaveWhich['Qty扫描'] = this.smForm.Qty
      squareScanSaveDo({
        token: this.token,
        saveData: this.smSaveWhich,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$notify({
              type: 'success',
              message: '扫码信息保存成功'
            })
            this.smSaveData.push(JSON.parse(JSON.stringify(this.smSaveWhich)))
            this.smForm = {}
          }
          this.$refs.Wafer_ID.select()
        })
        .catch(err => console.log(err))
    },
    SmUseData(item) {
      this.smSaveWhich = item
      this.$refs.Qty.select()
    },
    SmShowTable() {  // 显示已扫描
      this.step1 = this.step3 = false
      this.step2 = true
    },
    SmCl() {    // 取消
      this.step1 = true
      this.step2 = this.step3 = this.smLayer = false
      this.smForm = {}
      this.smConfirm = []
    },
    SmClear() {
      this.$confirm('确定要清空所有已扫描方片信息吗？')
        .then(res => {
          squareScanClear({
            token: this.token,
          })
            .then(res => {
              if (res.data.code === 'OK') {
                this.smSaveData = []
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    /* 扫码出库 */
    SoLayer() {
      squareSoGetCache({
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') this.soSaveData = res.data.rs
        })
        .catch(err => console.log(err))
      this.step1 = this.soLayer = true
      this.step2 = this.step3 = false
      setTimeout(() => {
        this.$refs.so_wid.select()
      }, 10)
    },
    SoWid() {   // Wafer ID 扫描完成
      this.soSaveWhich = {}
      const Wafer_ID = this.soForm.Wafer_ID
      if (!Wafer_ID) {
        this.$message({
          type: 'warning',
          message: 'Wafer ID 不为空'
        })
        return
      }
      squareSoWaferID({
        token: this.token,
        Wafer_ID,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            const rs = res.data.rs
            if (rs.length === 0) this.$alert('方片库存中未找到相关数据，或已被出库。')
            if (rs.length === 1) {
              this.soSaveWhich = rs[0]
              this.soSaveWhich.Qty修正 = this.soSaveWhich.Qty
              this.soSaveData.push(this.soSaveWhich)
              this.$refs.so_wid.select()
              this.$message({
                type: "success",
                message: '出库临时数据保存成功',
              })
            }
            if (rs.length > 1) this.$alert(`请注意！Wafer ID 重复。共 ${rs.length} 条`)
            this.soConfirm = rs
            this.$refs.so_wid.select()
          } else this.$refs.so_wid.select()
        })
        .catch(err => console.log(err))
    },
    /*
    SoSave() {   // Qty 也扫描完成
    },
    */
    SoClear() {   // 清空已扫描
      this.$confirm('确定要清空所有已扫描方片信息吗？')
        .then(res => {
          squareSoClear({
            token: this.token,
          })
            .then(res => {
              if (res.data.code === 'OK') {
                this.soSaveData = []
              }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    SoShowTable() {   // 显示已扫描
      this.step2 = true
      this.step1 = this.step3 = false
    },
    SoCl() {      // 取消
      this.step1 = true
      this.step2 = this.step3 = this.soLayer = false
      this.soForm = {}
      this.soConfirm = []
    },
    SoUseData(item) {   // 指定要使用的数据
      this.soSaveWhich = item
      squareSoUseWhich({
        token: this.token,
        id: item.id,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.soSaveWhich = item
            this.soSaveData.push(this.soSaveWhich)
            this.$refs.so_wid.select()
            this.$message({
              type: "success",
              message: '出库临时数据保存成功',
            })
          } else this.$refs.so_wid.select()
        })
        .catch(err => console.log(err))
    },
    /* 提交表单 */
    SubForm(formName, formData) {
      if (formName === 'sm') {
        squareScanSubForm({
          token: this.token,
          form: formData,
        })
          .then(res => {
            if (res.data.code === 'OK') {
              this.step1 = true
              this.step2 = this.step3 = this.smLayer = false
              this.smSaveData = []
              this.$message({
                type: 'success',
                message: '已经成功导入到方片数据库',
              })
              this.$refs.smForm.Clear(this.smFormData)
            }
          })
          .catch(err => console.log(err))
      }
      if (formName === 'so') {
        squareSoSubForm({
          token: this.token,
          form: formData,
        })
          .then(res => {
            if (res.data.code === 'OK') {
              this.step1 = true
              this.step2 = this.step3 = this.soLayer = false
              this.soSaveData = []
              this.$message({
                type: 'success',
                message: '方片出库成功',
              })
              this.$refs.soForm.Clear(this.soFormData)
              formRequest(baseUrl + '/square-plugin-so-download', {
                token: this.token,
                fileName: res.data.fileName,
                客户: formData.客户,
              })
            }
          })
          .catch(err => console.log(err))
      }
    },
    /* 表单返回 */
    Back(formName) {
      this.step3 = this.step1 = false
      this.step2 = true
    },
    /* 删除一行扫码内容 */
    DelRow(payload) {
      squareScanDelRow({
        token: this.$cookies.get('token'),
        row: payload.row,
        tableName: payload.tableName,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            if (payload.tableName === 'so') {
              const idx = this.soSaveData.findIndex(item => item === payload.row)
              this.soSaveData.splice(idx, 1)
            }
          }
        })
        .catch(err => console.log(err))
    },
    /* 智能筛选 */
    SmtLayer() {
      this.smtLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    WChange() {
      const obj = this.波段型号.find(item => item.label === this.smtForm.波段)
      if (obj) {
        this.型号列表 = obj.value
        if (this.smtForm.波段.indexOf('波段') !== -1) {
          delete this.smtForm.WLP1_MIN
          delete this.smtForm.WLP1_AVG
          delete this.smtForm.WLP1_MAX
        } else {
          delete this.smtForm.WLD1_MIN
          delete this.smtForm.WLD1_AVG
          delete this.smtForm.WLD1_MAX
        }
      }
    },
    SmtSearch() {
      const form = this.smtForm
      getSmtSKU({
        token: this.token,
        form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            formRequest(baseUrl + '/square-plugin-smt-download', {token: this.token})
            this.smtLayer = this.step2 = this.step3 = false
            this.step1 = true
          }
        })
        .catch(err => console.log(err))
    },
    QKLayer() {
      this.qkLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    QKNext() {
      this.step1 = this.step3 = false
      this.step2 = true
      const wid = this.qkForm.Wafer_ID.split('\n')
      const Wafer_IDs = []
      wid.forEach(item => {
        item = item.replace(/\r|\t| /g, '')
        if (item) Wafer_IDs.push(item)
      })
      this.qkForm.Wafer_IDs = Wafer_IDs
      delete this.qkForm.cwid
      setTimeout(() => this.$refs.qk.select(), 50)
    },
    QKEnt() {
      this.$refs.qk.select()
    }
  },
  activated() {
    document.title = `方片插件库 - ` + baseTitle
  },
  computed: {
    SmSumQty() {
      return s => {
        if (this.smSaveData.length > 0) {
          let sumQty = 0
          if (s === 'scan') this.smSaveData.forEach(item => sumQty += parseInt(item.Qty扫描))
          else this.smSaveData.forEach(item => sumQty += item.Qty)
          return sumQty
        } else return 0
      }
    },
    SoSumQty() {
      if (this.soSaveData.length > 0) {
        let sumQty = 0
        this.soSaveData.forEach(item => sumQty += parseInt(item.Qty修正))
        return sumQty
      } else return 0
    },
    GetQKBg() {
      const Wafer_IDs = this.qkForm.Wafer_IDs
      let c = this.qkForm.cwid
      if (!Wafer_IDs || !c) return ''
      c = c.replace(/ |\t|\r|\n/g, '')
      const chk = Wafer_IDs.find(item => item === c)
      if (chk) return 'background:green'
      else return 'background:red'
    },
  }
}
</script>

<style scoped>
table, tr, td {
  border-collapse: collapse;
  border: solid 2px #444040;
}

table tr td {
  padding: 0.5rem;
}
</style>
