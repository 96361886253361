export function formRequest(url, data) {
  let form = document.createElement('form');
  form.style.display = 'none';
  form.action = url;
  form.method = 'post';
  form.target = '_blank'
  document.body.appendChild(form);
  for (let dataKey in data) {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = dataKey;
    input.value = data[dataKey];
    form.appendChild(input);
  }
  form.submit();
  form.remove();
}
